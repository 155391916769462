<template>
  <AccountCreatedComponent @next="next" />
</template>
<script>
import AccountCreatedComponent from '@m/account/components/AccountCreated';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'AccountCreatedSuccess',
  components: {
    AccountCreatedComponent
  },
  computed: {
    ...mapGetters('account', ['getAddAccountBackPage'])
  },
  methods: {
    ...mapMutations('account', ['setAddAccountBackPage']),
    next() {
      if (this.getAddAccountBackPage) {
        this.$router.push(this.getAddAccountBackPage);
        setTimeout(() => {
          this.setAddAccountBackPage(null);
        }, 0);
      } else {
        this.$router.push('/account/list');
      }
    }
  }
};
</script>